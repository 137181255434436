
@font-face {
    font-family: 'Nexa Bold';
    src: url('../../fonts/nexa_bold-webfont.woff') format('woff'),
    url('../../fonts/nexa_bold-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium';
    src: url('../../fonts/titillium-semibold-webfont.woff2') format('woff2'),
    url('../../fonts/titillium-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Titillium';
    src: url('../../fonts/titillium-regular-webfont.woff2') format('woff2'),
    url('../../fonts/titillium-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}