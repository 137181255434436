.mb-140 {
  margin-bottom: 140px;
}
.react-progress-bar-spinner {
  display: none !important;
}

.react-progress-bar {
  position: absolute !important;
  top: 100% !important;
  .react-progress-bar-percent {
    background: #f48d21;
    box-shadow: none;
  }
}

.page-invoices__item {
  color: inherit !important;
  text-decoration: none !important;
}

.faq-modal {
  .modal-title {
    font-size: 20px;
  }
  .close {
    color: #ffffff;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      color: #ffffff !important;
      box-shadow: none;
    }
  }
  .list-group-item {
    cursor: pointer;
  }
  .list-group-item-heading {
    font-size: 16px;
    margin-bottom: 0;
  }
  .list-group-item-text {
    font-size: 14px;
    margin-top: 10px;
  }
}

.modal-pdf {
  min-width: 998px;
  height: 800px;
  .close {
    color: #ffffff;
    font-size: 30px !important;
    &:hover {
      color: #ffffff !important;
    }
  }
  .modal-title {
    font-size: 20px;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    .pdf-iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 768px) {
  .user_edit_avatar {
    text-align: center;
    .input__label {
      text-align: left;
    }
  }
  .user_edit_btn button{
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 600px) {
  .page-link {
    min-width: 28px !important;
  }
}
@media (max-width: 480px) {
  .page-link {
    min-width: 20px !important;
    font-size: 9px !important;
    padding: 2px 4px !important;
  }
  .auth__col--form {
    .input__label,
    .input__field {
      padding-left: 0 !important;
    }
  }
}

.m-bottom-0 {
  margin-bottom: 0;
}

.m-left-15 {
  margin-left: 15px;
}

.onLogin {
  padding: 0 74px;
  }

  .expired-email {
    width: 100%;
    height: 70vh;
    min-height: 300px;
    justify-content: center;

    &__container {
      width: fit-content;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto;

      &__img {
        display: flex;
        justify-content: center;
        img {
          filter: invert(60%) sepia(64%) saturate(1146%) hue-rotate(345deg) brightness(99%) contrast(92%);
          height: 150px;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;

        h4 {
          font-size: 18px;
        }

        h5 {
          font-size: 15px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    .expired-email {
      height: 100vh;

      &__container {
        width: 100%;
        height: 60%;
      }
    }
  }